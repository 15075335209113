import {
    Heading,
    Avatar,
    Box,
    Center,
    Image,
    Flex,
    Text,
    Stack,
    Button,
    useColorModeValue, Container,
} from '@chakra-ui/react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import headshot from '../photos/headshot4.png';
export default function Contact() {
    return (
        <Container textAlign={'center'} py={10} id={'Contact'}>
            <Heading textAlign={'center'}fontSize={'3xl'}>Contact Me</Heading>

    <Center py={6}>
            <Box
                maxW={'270px'}
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                boxShadow={'lg'}
                rounded={'md'}
                overflow={'hidden'}>
                <Image
                    h={'120px'}
                    w={'full'}
                    src={
                        'https://images.unsplash.com/photo-1612865547334-09cb8cb455da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
                    }
                    objectFit={'cover'}
                />
                <Flex justify={'center'} mt={-12}>
                    <Avatar
                        size={'xl'}
                        src={headshot  }
                        css={{
                            border: '2px solid white',
                        }}
                    />
                </Flex>

                <Box p={6}>
                    <Stack spacing={0} align={'center'} mb={5}>
                        <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                            Ernest Wong
                        </Heading>
                        <Text color={'gray.500'}>e222wong@uwaterloo.ca</Text>
                    </Stack>
                    <CopyToClipboard text={'e222wong@uwaterloo.ca'}
                                     >
                    <Button
                        w={'full'}
                        mt={8}
                        bg={useColorModeValue('#151f21', 'gray.900')}
                        color={'white'}
                        rounded={'md'}
                        _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }}>
                        Copy Email
                    </Button>
                    </CopyToClipboard>
                </Box>
            </Box>
    </Center></Container>
    );
}