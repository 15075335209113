import {Container, Flex, Heading, Link, Text, VStack} from "@chakra-ui/react";
import {
    Circle,
    Box,
    Image,
    Badge,
    useColorModeValue,
    Icon,
    chakra,
    Tooltip,
} from '@chakra-ui/react';
import {BsStar, BsStarFill, BsStarHalf} from 'react-icons/bs';
import {FiShoppingCart} from 'react-icons/fi';
import solvrpng from '../photos/solvr.png'
import motivespng from '../photos/motives.png'
import icecreamtruck from '../photos/icecreamtruck.png'
import robcoinpng from '../photos/robcoin.png'
import FTC from '../photos/FTC.png'
import cppng from '../photos/cp.png'
import CQL from '../photos/CQL.png'
import simplify from '../photos/simplify.png'
import airdrop from '../photos/airdrop.png'
const projects = [
    {
        name: 'Motives',
        technologies:
            [
                'React',
                'javascript',
                'nodejs',
                'express',
                'mongodb',
                'HTML',
                'CSS'
            ],
        component: (
            <Box py={'50%'}><Heading textAlign={'center'}> <Text as={'span'} bgGradient='linear(to-l, #2945CF, #1BAEE3)'
                                                                 bgClip='text'>
                Motives
            </Text></Heading></Box>),
        caption: 'Full-stack event planning social media app made with MERN stack',
        link:'/motives'

    },
    {
        name: 'Solvr.io',
        externalLink:true,
        technologies:
            [
                'Flutter/Dart',
                'javascript',
                'nodejs',
                'express',
                'postgresql',
                'python'
            ],
        imageURL: solvrpng,
        caption: 'Peer to peer tutoring app with over 500 users',
        margin:{margin:'10px auto'},
        link:'https://www.solvrapp.com/'
    },

    {        externalLink:true,

        shadow: "lg",
        name: 'Ice Cream Truck Tracker',
        technologies:
            [
                'Flutter/Dart',
                'typescript',
                'nodejs',
                'express',
                'Mongodb',
            ],
        imageURL:
        icecreamtruck
        ,
        caption: 'Mobile app for ice cream trucks to share current and future locations',
        margin:{margin:'10px auto'},
        link:'https://github.com/ernestwong1027/IceCreamTruckTrackerApp'
    },
    {        externalLink:true,

        name: 'SQLbutCQL',
        technologies:
            [
                'C'
            ],
        imageURL:
        CQL,
        caption: 'Made a relational database from scratch for fun. Implemented using B+ trees',
        link:'https://github.com/ernestwong1027/SQLbutCQL'
    },
    {        externalLink:true,

        name: 'Simplify.ai ',
        technologies:
            [
                'Python',
                'PyTorch',
                'Javascript',
                'React',
                'Flask'
            ],
        imageURL:
        simplify,
        caption: 'Full-stack machine learning application that converts lectures into text summaries. Made using fine-tuned machine learning models',
        link:'https://github.com/ernestwong1027/simplify.ai'
    },
    {        externalLink:true,

        name: 'Airdrop But Better',
        technologies:
            [
                'C++'
            ],
        imageURL:
        airdrop,
        caption: 'Cross-platform wireless file sharing interface. Used data compression and Huffman encoding',
        link:'https://github.com/ernestwong1027/airdrop-but-better'
    },

    {        externalLink:true,

        name: 'Robcoin',
        technologies:
            [
                'Python',
                'SQLITE',
                'Flask',
            ],
        imageURL:
        robcoinpng,
        caption: 'Cryptocurrency made from scratch implementing blockchain and proof of work',
        link:'https://github.com/STR-Coding-Club/RobCoin'
    },
    {        externalLink:true,

        name: 'FTC Robotics Subsystem and Command Framework',
        technologies:
            [
                'Java',
                'Android Studio',
            ],
        imageURL:
        FTC,
        caption: 'Framework made from scratch to enable modular programming between team members',
        link:'https://github.com/ernestwong1027/FTCSubsystemAndCommandFramework'
    },
    {        externalLink:true,

        name: 'Competitive Programming Solutions',
        technologies:
            [
                'Java'
            ],
        imageURL:
        cppng,
        caption: 'Set of 200+ competitive programming solutions, data structures and algorithms that helped achieve a perfect score on CCC 2021',
        link:'https://github.com/ernestwong1027/CompetitiveProgramming'
    },

]

export default function Projects(props) {

    return (
        <div id={'Projects'}>
            <Container textAlign={'center'} py={10}>
                <Heading textAlign={'center'}fontSize={'3xl'}>Projects</Heading>
                <Text fontSize={'2xl'}>Click on a project card to learn more about it!</Text>
                {/*<Link href={'https://github.com/ernest1027'} isExternal>Find even more of my projects on my github page by clicking here!</Link>*/}
            </Container>
            <Flex width={'100%'} alignItems={'center'} justifyContent={'space-around'} flexWrap={'wrap'}>
                {projects.map((project) => <Card {...project} key={project.name}></Card>)}
            </Flex>

        </div>
    )
}


function Card(props) {
    return (
    <Link href={props.link} style={{ textDecoration: 'none' }} isExternal={props.externalLink}>
        <Flex className={'cardBox'} p={50} alignItems="center" justifyContent="center">
            <Box
                className={'projectCard'}
                bg={useColorModeValue('white', 'gray.800')}
                maxW="400px"
                borderWidth="1px"
                rounded="lg"
                shadow="lg"
                position="relative">
                <Box maxH={'420px'}>
                    {props.component ? props.component :
                        <Image
                            shadow={props.shadow}
                            maxH={props.maxH?props.maxH:'420px'}
                            style={props.margin?props.margin:{margin: 'auto auto'}}
                            src={props.imageURL}
                            alt={`Picture of ${props.name}`}
                            roundedTop="lg"
                        />}
                </Box>
                <Box p="6">
                    <Box d="flex" alignItems="baseline" flexWrap={'wrap'}>
                        {props.technologies.map((technology) => {
                            return (
                                <Badge rounded="full" my='2' mx='2' px="2" fontSize="0.8em" colorScheme="blue">
                                    {technology}
                                </Badge>
                            )
                        })}

                    </Box>
                    <Flex mt="1" justifyContent="space-between" alignContent="center">
                        <Box
                            fontSize="2xl"
                            fontWeight="semibold"
                            as="h4"
                            lineHeight="tight"
                            // height={'70px'}
                        >
                            {props.name}
                        </Box>
                    </Flex>
                    <Text>{props.caption}</Text>

                </Box>
            </Box>

        </Flex>
    </Link>
    );
}

