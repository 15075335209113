
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    Link,
    useColorModeValue, UnorderedList, ListItem,
} from '@chakra-ui/react';

import { HiDesktopComputer} from "react-icons/hi";
import {CgMathDivide} from "react-icons/cg";




const Feature = ({ text, icon, iconBg }) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

function SplitWithImage() {
    return (
        <Flex  px={{base:10,md:36}}  py={12} wrap={'wrap'}>
            <Heading width={{base:'100%',lg:'50%'}}textAlign={'left'}fontSize={'5xl'}>Awards</Heading>
            <Stack spacing={4} width={{base:'100%',lg:'50%'}}>

                <Text color={'gray.500'} fontSize={'lg'}>
                    A list of my academic and leadership awards
                </Text>
                <Stack
                    spacing={4}
                    divider={
                        <StackDivider
                            borderColor={useColorModeValue('gray.100', 'gray.700')}
                        />
                    }>

                    <Feature
                        icon={
                           <Image src={'https://upload.wikimedia.org/wikipedia/en/thumb/6/6e/University_of_Waterloo_seal.svg/1200px-University_of_Waterloo_seal.svg.png'}></Image>
                        }
                        iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                        text={'University of Waterloo Mathematics National Scholarship ($25,000 - Highest Value)'}
                    />
                    <Feature
                        icon={<Icon as={HiDesktopComputer} color={'green.500'} w={5} h={5} />}
                        iconBg={useColorModeValue('green.100', 'green.900')}
                        text={'National Champion (Perfect Score) - 2021 Canadian Computing Competition '}
                    />
                    <Feature
                        icon={<Icon as={HiDesktopComputer} color={'green.500'} w={5} h={5} />}
                        iconBg={useColorModeValue('green.100', 'green.900')}
                        text={'Bronze Medal - 2021 Canadian Computing Olympiad'}
                    />
                    <Feature
                        icon={
                            <Icon as={CgMathDivide} color={'purple.500'} w={5} h={5}></Icon>
                        }
                        iconBg={useColorModeValue('purple.100', 'purple.900')}
                        text={'National Champion - 2021 Canadian Team Mathematics Contest (CTMC) '}
                    />
                    <Feature
                        icon={
                            <Icon as={CgMathDivide} color={'purple.500'} w={5} h={5}></Icon>
                        }
                        iconBg={useColorModeValue('purple.100', 'purple.900')}
                        text={'National champion (Perfect Score) – 2020 University of Waterloo Grade 11 Fermat Math Contest  '}
                    />
                    <Feature
                        icon={
                            <Icon as={CgMathDivide} color={'purple.500'} w={5} h={5}></Icon>
                        }
                        iconBg={useColorModeValue('purple.100', 'purple.900')}
                        text={'Honorable Mention (Top 25 Student)  - 2021 University of Waterloo Grade 12 Euclid Math Contest '}
                    />

                </Stack>
                <Accordion allowToggle sx={{borderBottomWidth:'0', borderTopWidth:'0'}}>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    Leadership Awards
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <UnorderedList>
                                <ListItem>2021 - YCDSB Faith and Leadership Award</ListItem>
                                <ListItem>2021 - Science Spirit Award</ListItem>
                                <ListItem>2021 - Leadership in Computer Technology Award</ListItem>
                            </UnorderedList>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Stack>
        </Flex>
    );
}

export default function Awards(props) {

    return (
        <div id={'Awards'} style={{width:'100%'}}>
            <SplitWithImage/>
        </div>
    )
}


