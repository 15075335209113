import logo from './logo.svg';
import './App.css';
import NavBar from "./Components/Navbar";
import Header from "./Components/Header";
import Projects from "./Components/Projects";
import Skills from "./Components/Skills";
import Contact from "./Components/Contact";
import WorkExperiences from "./Components/WorkExperiences";
import Awards from "./Components/Awards";
import Clubs from "./Components/Clubs";
import HomePage from "./pages/HomePage";
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import GenericProjectPage from "./pages/GenericProjectPage";

function App() {
    return (
       <Router>
           <div className={'app'}>
           <Switch>
               <Route path="/motives">
                   <GenericProjectPage/>
               </Route>
               <Route path="/:part">
                   <HomePage></HomePage>
               </Route>
               <Route path="/">
                   <HomePage></HomePage>
               </Route>
           </Switch>
           </div>
       </Router>

    );
}

export default App;
