import NavBar from "../Components/Navbar";
import Header from "../Components/Header";
import Projects from "../Components/Projects";
import Skills from "../Components/Skills";
import WorkExperiences from "../Components/WorkExperiences";
import Awards from "../Components/Awards";
import Clubs from "../Components/Clubs";
import Contact from "../Components/Contact";
import {useEffect} from "react";
import {useParams} from "react-router";
import {scroller} from "react-scroll";

export default function HomePage() {
    const {part} = useParams();
    useEffect(()=>{
        scroller.scrollTo(part, {duration: 2000,
            delay: 'easeInOutQuart',
            smooth: true,offset:-100})
    },[])
    return (
        <>
            <NavBar onHomePage={true}/>
            <Header/>
            <Projects/>
            <Skills/>
            <WorkExperiences/>
            <Awards/>
            <Clubs/>
            <Contact/>
        </>
    )
}