

import {
    Link,
    Box,
    Container,
    Heading,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    HStack,
    VStack, UnorderedList, ListItem, Flex,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';


 function GridListWithHeading() {
    return (


            <Box width={{base:'100%',lg:'50%'}} px={{base:10,md:36}}  >
                <Flex justifyContent={'space-between'} wrap={'wrap'}>
                    <Box >
                        <Heading fontSize={'xl'}>University</Heading>
                        <UnorderedList>
                            <ListItem>Faculty of Mathematics Society - First Year Representative</ListItem>
                            <ListItem>Computer Science Club - External Affairs</ListItem>
                            <ListItem>Waterloop - Firmware Team Member</ListItem>
                        </UnorderedList>
                        <Heading pt={5} fontSize={'xl'}>High School</Heading>
                        <UnorderedList>
                            <ListItem>Environmental Council - President</ListItem>
                            <ListItem>Connect4Cancer- President</ListItem>
                            <ListItem>Robotics Team – Programming Team Captain</ListItem>
                            <ListItem>Math Club – VP</ListItem>
                            <ListItem>Coding Club – VP of Curriculum</ListItem>
                            <ListItem>Transition Activity Committee – Founder</ListItem>
                            <ListItem>Markham Mayor's Youth Council - Executive Member</ListItem>
                        </UnorderedList>
                    </Box>

                </Flex>
            </Box>

    );
}

export default function Clubs(props) {

    return (
        <Flex id={'Clubs'} justifyContent={'space-between'} direction={'row-reverse'} wrap={'wrap'}>
            <Box width={{base:'100%',lg:'50%'}} textAlign={{base:'left', lg:'right'}} px={{base:10,md:36}}  >
                <Heading fontSize={'5xl'}>Clubs</Heading>
            </Box>
            <GridListWithHeading/>


        </Flex>
    )
}


