import { ReactNode } from 'react';
import {
    Box,
    Flex,
    Avatar,
    HStack,
    Link,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack, useColorMode,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import {FiMoon} from 'react-icons/fi'
import * as Scroll from 'react-scroll';
import {scroller} from "react-scroll";
import {useHistory} from "react-router-dom";

const Links = [
    // 'Resume',
    'About', 'Projects', 'Skills','Work Experience', 'Awards', 'Clubs', 'Contact'];


export default function NavBar(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <>
            <Box  px={4} bgColor={colorMode==='light'? 'white':'gray.800'} style={{position:'sticky',  zIndex:'100', top:'0'}}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ lg: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack width={'100%'} spacing={8} alignItems={'center'} justify={'space-between'}>
                        <Box>Ernest Wong</Box>
                        <HStack
                            as={'nav'}
                            spacing={4}
                            display={{ base: 'none', lg: 'flex' }}>
                            <Button onClick={toggleColorMode}>
                                <FiMoon/>

                            </Button>


                            {Links.map((link) => (
                                <NavButton onHomePage={props.onHomePage}  key={link}>{link}</NavButton>
                            ))}
                        </HStack>
                    </HStack>
                </Flex>

                {isOpen
                    ? (
                        <Box pb={4} display={{ lg: 'none' }}>
                            <Stack as={'nav'} spacing={4}>

                                <Button padding={'20px'} width={'50px'} onClick={toggleColorMode}>
                                    <FiMoon/>

                                </Button>
                                {Links.map((link,idx) => (
                                    <NavButton onHomePage={props.onHomePage} isMobile={true} onClose={onClose} key={link} index={idx}>{link}</NavButton>
                                ))}
                            </Stack>
                        </Box>
                    )
                    : null}
            </Box>
        </>
    );
}

const NavButton = ({ children,index,onHomePage,isMobile,onClose }) => {
    const history = useHistory();
    return (

    <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        onClick={()=>{
            if(children==='Resume'){
                let win = window.open('https://drive.google.com/file/d/1N9CBOvPlwj6LJ20IefKaglG_O7q2vW0E/view', '_blank');
                win.focus();
            }
            if(onHomePage)
            {
                if(isMobile){
                    onClose();
                }
                scroller.scrollTo(children==='Work Experience'?'WorkExp':children, {duration: 2000,
                    delay: 'easeInOutQuart',
                    smooth: true,offset:(isMobile?-500:-100)})
                return;
            }
            history.push('/'+(children==='Work Experience'?'WorkExp':children))

        }}>
        {children}
    </Link>
)};
