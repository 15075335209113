import {Container, Flex, Heading, Link, Text, Tooltip} from "@chakra-ui/react";
import {
    FaGithub,
    FaLinkedinIn,
    FaRegEnvelope,
    FaYoutube,
    FaTwitch,
    FaMediumM,
} from "react-icons/fa";
import Contact from "./Contact";

export default function Header(props) {
    return (
        <Container textAlign={'center'} id={'About'}>
            <Heading  fontWeight={300} textAlign={'center'}>Hi, I'm Ernest Wong 👋</Heading>
            <Heading  fontWeight={800} textAlign={'center'}>I'm a student who loves using software to solve problems!</Heading>
            <Text>Currently studying at the University of Waterloo in the Computer Science and Business Administration Double Degree Program</Text>
            <Social></Social>

        </Container>
    )
}

const Social = () => {
    const component = [
        [<FaRegEnvelope />, "mailto: e222wong@uwaterloo.ca",'Email'],
        [<FaGithub />, "https://github.com/ernestwong1027",'Github'],
        [<FaLinkedinIn />, "https://www.linkedin.com/in/ernestwong",'Linkedin'],
    ];

    const icons = component.map((icon, index) =>
        <Tooltip hasArrow label={icon[2]}
                 fontSize='md'>
            <div><a
            key={index}
            href={icon[1]}
            target="_blank"
            rel="noopener noreferrer"
        >

            {icon[0]}
        </a>
            </div>
        </Tooltip>
    );
    return (
        <Flex alignContent={'center'} py={5} justifyContent={'space-evenly'}>
            {icons}
        </Flex>
    );
};

