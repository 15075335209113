// theme.js
// 1. Import the utilities
import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

// 2. Update the breakpoints as key-value pairs
const breakpoints = createBreakpoints({
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
})

// 2. Add your color mode config
const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
    breakpoints
}

// 3. extend the theme
const theme = extendTheme({ config },{breakpoints})

export default theme