import {
    Stack,
    Container,
    Box,
    Flex,
    Text,
    Heading,
    SimpleGrid,
    Image,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    UnorderedList,
    ListItem,
    Accordion, StackDivider, useColorModeValue, Icon,
} from '@chakra-ui/react';
import facedriveFoods from '../photos/facedriveFoods.png';
import kiavi from '../photos/kiavi_logo.png'
import solvr from '../photos/solvr_logo.jpeg'
import waterloop from '../photos/waterloop_logo.jpeg'
import {HiDesktopComputer} from "react-icons/hi";
import {CgMathDivide} from "react-icons/cg";




export default function WorkExperiences(props) {

    return (
        <div id={'WorkExp'} style={{width:'100%'}}>
            <Container  py={10}>


            </Container>
            <Flex  px={{base:10,md:36}}  py={12} wrap={'wrap'} direction={'row-reverse'}>
                <Heading width={{base:'100%',lg:'50%'}}textAlign={{base:'left',lg:'right'}}fontSize={'4xl'}>Work Experience</Heading>
                <Stack spacing={4} width={{base:'100%',lg:'50%'}}>

                        <Accordion allowToggle sx={{borderBottomWidth:'0', borderTopWidth:'0'}}>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>

                                            <Image width={{base: '10%', md: '5%'}} src={kiavi}></Image>
                                            <Text
                                                fontFamily={'heading'}
                                                fontWeight={700}
                                                mx={12}
                                                fontSize={{base: 'lg', md: 'xl'}}
                                                textAlign={'left'}
                                            >
                                                Kiavi  -  Software Engineering Intern
                                            </Text>
                                        (San Francisco, CA)
                                        <AccordionIcon />

                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Text><strong>May 2022 - Aug 2022 </strong> </Text>
                                        <Text fontSize={'md'} >
                                            Designed and developed microservices and web applications using <strong>Kotlin, React, gRPC, event-based architecture,</strong>  and
                                            <strong> caching</strong> to automate and improve the mortgage
                                            securitization sale workflow, decreasing the workflow completion time by <strong>50%</strong>. Worked with <strong>Snowflake</strong> data pipelines
                                            to minimize stale data within microservices. Maintained and added new features
                                            to internal monolithic application using technologies such as <strong>Ruby on Rails, AWS, Open Policy Agent</strong>, <strong>GraphQL</strong> and <strong>Docker</strong>
                                        </Text>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Image width={{base: '10%', md: '5%'}} src={facedriveFoods}></Image>
                                        <Box textAlign={'left'}>
                                             <Text
                                            fontFamily={'heading'}
                                            fontWeight={700}
                                            mx={12}
                                            fontSize={{base: 'lg', md: 'xl'}}
                                        >
                                            {'Facedrive Foods (Steer)  - '}
                                        </Text>
                                            <Text
                                                fontFamily={'heading'}
                                                fontWeight={700}
                                                mx={12}
                                                fontSize={{base: 'lg', md: 'xl'}}
                                            >
                                                Backend Development Intern
                                            </Text>

                                        </Box>


                                        (Toronto, ON)
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Text><strong>Jun 2021 - Aug 2021 </strong> </Text>
                                    <Text fontSize={'md'} >
                                        Worked with backend development team to integrate new features into backend API written in <strong>PHP </strong>
                                        and <strong>Laravel</strong>.
                                        Redesigned driver location API using <strong>Typescript</strong>,
                                        <strong> MongoDB</strong>, and <strong>Express </strong>
                                        for more
                                        efficient and advanced queries. Developed full-stack internal tooling for accounting department.
                                        Integrated load tests, integration tests, hosting, git flow and software redundancy for microservices
                                        using <strong>PM2</strong>, <strong>Jest</strong>, <strong>Nginx</strong>, <strong>EC2</strong>,
                                        and <strong>AWS</strong> </Text>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>

                                        <Image width={{base: '10%', md: '5%'}} src={solvr}></Image>
                                        <Text
                                            fontFamily={'heading'}
                                            fontWeight={700}
                                            mx={12}
                                            fontSize={{base: 'lg', md: 'xl'}}
                                            textAlign={'left'}
                                        >
                                            Solvr.io -  Chief Technology Officer
                                        </Text>
                                        (Toronto, ON)
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Text fontSize={'md'} >
                                       <strong>Apr 2021 - Present </strong> </Text>
                                    <Text fontSize={'md'} >
                                        Developed a peer-to-peer tutoring mobile app and backend with over <strong>500 users</strong> and <strong>40K line</strong> codebase using
                                        <strong> Flutter, Javascript, Node.js,</strong> and <strong>Express</strong>. Led a team of developers working with <strong>Agile Development</strong> , design documentation
                                        and strong test suites. Created internal tooling for data analytics using <strong>React</strong> and <strong>Python</strong>. Used technologies such as
                                        <strong> AWS, Docker, Firebase Analytics</strong> and <strong>Stripe</strong>.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>

                                        <Image width={{base: '10%', md: '5%'}} src={waterloop}></Image>
                                        <Text
                                            fontFamily={'heading'}
                                            fontWeight={700}
                                            mx={12}
                                            fontSize={{base: 'lg', md: 'xl'}}
                                            textAlign={'left'}
                                        >
                                            Waterloop - Firmware Developer
                                        </Text>
                                        (Waterloo, ON)
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Text fontSize={'md'} ><strong>Sep 2021 - Present </strong> </Text>
                                    <Text fontSize={'md'} >Developed firmware for University of Waterloo's Hyperloop team using <strong>C</strong> and <strong>C++</strong>.
                                        Developed and tested firmware for motor controller using technologies/concepts such as
                                        <strong> STM32 Microcontroller, SVPWM, CAN, PID, I2C</strong> and <strong>DMA</strong>. Integrated multi-threading and concurrency into firmware using a real time operating system (FreeRTOS). Worked with <strong>Time Series DB </strong>
                                        and <strong>Docker</strong> to develop real-time low latency logging platform for Hyperloop.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>




                </Stack>

            </Flex>


                {/*<Box mb={{ base: 8, md: 20 }} width={{base:'100%', lg:'50%'}}>*/}

                {/*    <Text*/}
                {/*        fontFamily={'heading'}*/}
                {/*        fontWeight={700}*/}
                {/*        textTransform={'uppercase'}*/}
                {/*        mb={3}*/}
                {/*        fontSize={{base: 'lg', md: 'xl'}}*/}
                {/*    >*/}
                {/*        Facedrive Foods (FoodHwy)<Text*/}
                {/*        fontFamily={'heading'}*/}
                {/*        fontWeight={700}*/}
                {/*        textTransform={'uppercase'}*/}
                {/*        mb={3}*/}
                {/*        fontSize={{base: 'sm', md: 'md'}}*/}
                {/*    >Jun 2021 - Aug 2021*/}
                {/*        <Image width={{base: '20%', md: '10%'}} src={facedriveFoods}></Image></Text>*/}

                {/*    </Text>*/}
                {/*    <Heading*/}
                {/*        mb={5}*/}
                {/*        fontSize={{ base: '2xl', lg: '3xl' }}>*/}
                {/*        Backend Development Intern*/}
                {/*    </Heading>*/}
                {/*    <Text fontSize={'md'} >*/}
                {/*        Worked with backend development team to integrate new features into backend API written in <strong>PHP </strong>*/}
                {/*        and <strong>Laravel</strong>.*/}
                {/*        Redesigned driver location API using <strong>Typescript</strong>,*/}
                {/*        <strong> MongoDB</strong>, and <strong>Express </strong>*/}
                {/*        for more*/}
                {/*        efficient and advanced queries. Developed full-stack internal tooling for accounting department.*/}
                {/*        Integrated load tests, integration tests, hosting, git flow and software redundancy for microservices*/}
                {/*        using <strong>PM2</strong>, <strong>Jest</strong>, <strong>Nginx</strong>, <strong>EC2</strong>,*/}
                {/*        and <strong>AWS</strong>*/}

                {/*    </Text>*/}
                {/*</Box>*/}

            </div>

    )
}


