import {BsStar, BsStarFill, BsStarHalf} from "react-icons/bs";
import {Box, Container, Flex, Heading, Text} from "@chakra-ui/react";

export default function Skills(props) {
    return (
        <Box textAlign={'center'}  id={'Skills'}>
            <Container textAlign={'center'} py={10}>
                <Heading textAlign={'center'}fontSize={'3xl'}>Skills</Heading>

            </Container>
            <Flex wrap={'wrap'} justifyContent={'space-between'}>
                <Skill rating={4} skillName={'Javascript'}/>
                <Skill rating={4} skillName={'Java'}/>
                <Skill rating={4} skillName={'Node.js'}/>
                <Skill rating={4} skillName={'Express'}/>
                <Skill rating={4} skillName={'React'}/>
                <Skill rating={4} skillName={'SQL'}/>
                <Skill rating={3.5} skillName={'Typescript'}/>
                <Skill rating={3.5} skillName={'Python'}/>
                <Skill rating={3.5} skillName={'Flutter/Dart'}/>
                <Skill rating={3.5} skillName={'HTML'}/>
                <Skill rating={3.5} skillName={'CSS'}/>
                <Skill rating={3} skillName={'MongoDB'}/>
                <Skill rating={3} skillName={'PHP/Laravel'}/>
                <Skill rating={3} skillName={'Ruby/Ruby on Rails'}/>
                <Skill rating={3} skillName={'Kotlin'}/>
                <Skill rating={3} skillName={'Tensorflow'}/>
                <Skill rating={2.5} skillName={'Swift'}/>
            </Flex>
        </Box>)
}

function Skill({rating, skillName}) {
    return (
        <Box py={'20px'} d="flex" width={{base: '100%', lg: '50%'}} alignItems="center" justifyContent={'space-around'}>
            <Heading fontSize={{sm: '15px', md: '24px'}} width={'30%'}>{skillName}</Heading>
            <Box  width={'25%'} d="flex" alignItems="center">

                {Array(5)
                    .fill('')
                    .map((_, i) => {
                        const roundedRating = Math.round(rating * 2) / 2;
                        if (roundedRating - i >= 1) {
                            return (
                                <BsStarFill
                                    size={'10%'}
                                    key={i}
                                    style={{marginLeft: '1', color:'#ffde34'}}
                                    color={i < rating ? 'teal.500' : 'gray.300'}
                                />
                            );
                        }
                        if (roundedRating - i === 0.5) {
                            return <BsStarHalf size={'10%'} key={i} style={{marginLeft: '1', color:'#ffde34'}}/>;
                        }
                        return <BsStar size={'10%'} key={i} style={{marginLeft: '1', color:'#ffde34'}}/>;
                    })}
            </Box>
        </Box>
    );
}
